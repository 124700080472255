import React from "react"


export default function ComingSoon() {

  return (
    <div className='four-oh-page-container'>
      <div className='four-oh-page-words'>
        FEATURE COMING SOON!
      </div>
    </div>
  )
}
