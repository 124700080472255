import React from "react"


export default function NotFound() {

  return (
    <div className='four-oh-page-container'>
      <div className='four-oh-page-words'>
        SORRY! RESOURCE NOT FOUND
      </div>
    </div>
  )
}
